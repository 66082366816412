import $ from "jquery";

const INFO = {
  title: "<h1>Lo sapevi che...</h1>",
  text: `
    <p><i>“Accolti con gusto”</i> nasce da un&#39;idea della Società Semplice Agricola Campo dei Fiori, finanziata dalla misura 6.2.1 del PSR Lazio attraverso  il GAL Terre di Argil.</p> 
    <p>Accolti con gusto è un punto di accoglienza turistico che porta il visitatore alla scoperta delle tradizioni culturali ed enogastronomiche che il  territorio del comune di Arnara offre.</p>
    <p>Il punto di accoglienza che si trova al centro del paese, all&#39;interno delle mura di cinta, offre la possibilità di prenotare una degustazione di     prodotti tipici, di acquistare gli stessi in loco oppure direttamente dalle aziende produttrici dislocate nelle vicinanze.</p>
    <p>Una delle prerogative del punto vendita consiste nella possibilità di noleggiare bici a pedalata assistita che permetteranno al cliente di fare il tour  dei punti di interesse e delle aziende produttrici e di passeggiare tra le campagne di Arnara, constatando da vicino la genuinità dei prodotti e l&#39;  accogliente calore degli arnaresi. In alcune delle aziende sarà inoltre possibile vivere l&#39;esperienza unica di raccogliere i prodotti con le proprie  mani.
    </p>
  `
}

const APP = {
  title: "<h1>In Arrivo l&#39;app di Accolti Con Gusto!</h1>",
  text: `
    <p>Stiamo creando qualcosa di straordinario: l'app di <i>Accolti Con Gusto!</i> Sarà il tuo passaporto per avvicinarti alla natura, alla vita rurale e a prodotti freschi direttamente dalla nostra azienda.</p>
    <br>
    <p>Il nostro team sta lavorando instancabilmente per portarti un'esperienza agricola senza precedenti.</p>
    <br>
    <p>Non vediamo l'ora di condividere con te l'autenticità della vita rurale attraverso l'app di Accolti Con Gusto.</p>
  `
}

class Modal {
  // 1. describe and create/initiate our object
  constructor() { // info | app
    this.openButton = $(".openmodal");
    this.closeButton = $("#close-modal");
    this.modal = $(".modal");
    this.modalcontent = $(`.modal article`);
    this.modaltitle = $(`.modal h1`); 
    this.closeOverlay = $(".modal-overlay");
    this.events();    
  }
  
  // 2. events
  events() {
    this.openButton.on("click", (e) => {
      const id = e.currentTarget.id;
      if(id === "info") {
        this.showModalInfo()
      } else if(id === "google" || id === "apple") {
        this.showModalApp()
      }
    });

    this.closeButton.on("click", this.hidemodal.bind(this));
    this.closeOverlay.on("click", this.hideoverlay.bind(this));
  }

  // 3. methods (function, action...)
  showModalInfo(){
    this.modal.addClass("show");
    this.modalcontent.html(INFO.text);
    this.modaltitle.html(INFO.title);
  }
      
  showModalApp(){
    this.modal.addClass("show");
    this.modalcontent.html(APP.text); 
    this.modaltitle.html(APP.title);
  }

  hidemodal(){
    this.modal.removeClass("show");
  }

  hideoverlay(){
    this.modal.removeClass("show");
  }
}

export default Modal;